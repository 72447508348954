/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import { Image, Button, FormGroup, Row, Col } from 'react-bootstrap';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as userActions from "../../actions/user-actions";
import Config from "../../config";
import {
    Validator,
    RequiredRule
} from 'devextreme-react/validator';
import {TextBox,Button as ButtonSM} from 'devextreme-react';
import EventTracking from "../common/tracking/event";
import ReactLoading from "react-loading";
import moment from 'moment';

class LoginPage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.txtUserID = null;
        this.timeBlock = 600000; //10 minutes

        this.setTextBox = (ref) => {
            this.txtUserID = ref.instance;
        };

        this.state = {
            UserTracking: "",
            message: "",
            isSubmit: false,
            eUsernameFlag: null,
            ePasswordFlag: null,
            lang:'vi',
            userID:'',
            passW:''
        };
    };

    componentDidMount(){
        let lang = Config.getLocalStorage("langCRM");
        if (lang!==null && typeof lang !=='undefined'){

            this.setState({lang:lang});
        }
        this.txtUserID.focus();
    }

    onLogout() {
        if(this.props.onLogout) {
            this.props.onLogout();
        }
    };

    onChange() {
        const {message} = this.state;

        if (!message || message.length === 0) {
            return;
        }

        this.setState({eUsernameFlag: null, ePasswordFlag: null});

        if (this.checkValidate()) {
            this.setState({message: ""});
        }
    }

    onTextValueChanged(e) {
        // console.log('============onTextValueChanged===========', e);
        switch (e.event.target.name) {
            case "UserID":
                this.setState({userID:e.event.target.value})
                break;
            case "PassW":
                this.setState({passW:e.event.target.value})
                break;
            default:
                break;
        }
    }

    onKeyDown(e){
        if (this.state.userID!=='') {
            this.setState({passW: e.event.target.value});
            switch (e.event.key) {
                case 'Enter':
                    this.onFormSubmit();
                    break;
                default:
                    break;
            }
        }
    }

    onKeyDownAccount(e){
        if (this.state.passW!==''){
            this.setState({userID:e.event.target.value});
            switch (e.event.key) {
                case 'Enter':
                    this.onFormSubmit();
                    break;
                default:
                    break;
            }
        }
    }

    onEventSave() {
        // const submit = document.getElementById("btnSubmit");
        // submit.click();
        this.onFormSubmit();
    }

    onFormSubmit() {
        const {userID, passW} = this.state;
        if (userID==='' ||passW==='' ) return null;

        // check User is login failed 3 times
        let blockListLogin = {};
        if(userID){
            blockListLogin = Config.getLocalStorage('BLOCK_LIST_LOGIN', true) || {};
            const time = blockListLogin[userID] ?  moment().valueOf() - blockListLogin[userID] : null;
            if(time && time < this.timeBlock){ //10 minutes
                this.setState({message: Config.lang(`Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%${moment(this.timeBlock - time).format(`m [${Config.lang('phut')}] ss ${Config.lang('Giay').toLowerCase()}`)}%_de_dang_nhap_lai`)});
                return null;
            }
            else if(blockListLogin[userID]){
                delete blockListLogin[userID];
                Config.setLocalStorage('BLOCK_LIST_LOGIN', JSON.stringify(blockListLogin));
            }
        }

        this.setState({isSubmit: true});
        this.props.userActions.login({
            username: userID,
            password: passW,
            language: Config.language || "84"
        }, (error, data) => {
            if(error){
                let errorCode = error.code || null;
                let message = "";

                switch (errorCode) {
                    case "US004":
                        message =  Config.lang("CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    case "US029":
                        message = Config.lang("CRM_Tai_khoan_khong_co_quyen");
                        break;
                    case "US033":
                        message = Config.lang("CRM_Tai_khoan_bi_khoa_10_phut");
                        break;
                    case "US038":
                        message = Config.lang(`Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%10 ${Config.lang('phut')}%_de_dang_nhap_lai`);

                        // save User to list user is login failed 3 times
                        const blockListLogin = Config.getLocalStorage('BLOCK_LIST_LOGIN', true) || {};
                        blockListLogin[userID] = moment().valueOf();
                        Config.setLocalStorage('BLOCK_LIST_LOGIN', JSON.stringify(blockListLogin));

                        break;
                    default:
                        message = error.message ||Config.lang("CRM_Loi_chua_xac_dinh");
                        break;
                }

                this.setState({isSubmit: false, message: message});
                return false;
            }

            let user = data.user || {};
            const expire = new Date().getTime() + 7*24*60*60*1000;

            Config.token = {
                id: data.token,
                expire: expire
            };

            user.type = 2;
            if(Config.localization!==null){
                Config.localization.setLanguage(this.state.lang);
                Config.setLocalStorage("langCRM",this.state.lang);
            }

            Config.setLocalStorage('PROFILE', Config.encryptData(JSON.stringify(user)));
            Config.setLocalStorage('TOKEN', JSON.stringify(Config.token));

            this.setState({UserTracking: user}, () => {

                setTimeout(()=>{
                    this.setState({isSubmit: false, message: ''});
                    window.location.reload();
                }, 1000);
            });
        });
    }

    setEnglish(){
        if(Config.localization!==null){
            Config.localization.setLanguage('en');
            Config.language="01";
            this.setState({lang:'en'});
            Config.setLocalStorage("langCRM",'en');
        }
    }

    setVN(){
        if(Config.localization!==null){
        Config.localization.setLanguage('vi');
        Config.language="84";
        this.setState({lang:'vi'});
        Config.setLocalStorage("langCRM",'vi');
        }
    }

    render(){
        const UserTracking = this.state.UserTracking || false;
        return(
            <div className="login-container">
                {UserTracking && <EventTracking category={"TRACKING_USER"} action={"LOGIN"} label={UserTracking.UserID} /> }

                <div className="login-bound">
                    <Image src={require('../../images/e-logo.png')} style={{height: 82}}/>
                    <div className="login-form">
                        <FormGroup >
                            <Row style={{ display: "flex",alignItems: "flex-end"}}>
                                <Col sm={12}>
                                    <div >
                                        {/* eslint-disable */}
                                        <a className={this.state.lang==='en' ? "flag-focus pull-right" : "pull-right"}  id="lnkEnglish" onClick={()=>this.setEnglish()}>
                                            <Image style={{height: '32px',width: '32px'}}  src={require('../../images/English.png')}  alt="English"/>
                                        </a>

                                        <a className={this.state.lang==='vi' ? "flag-focus pull-right" : "pull-right"} id="lnkVietNam" onClick={()=>this.setVN()}>
                                            <img style={{height: '32px',width: '32px'}} src={require('../../images/Vietnam.png')}  alt="Việt Nam"/>
                                        </a>
                                        {/* eslint-disable */}
                                    </div>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup >
                            <Row>
                                <Col sm={12}>
                                    <TextBox ref={this.setTextBox}
                                             name={"UserID"}
                                             value={this.state.userID}
                                             onChange={(e) => this.onTextValueChanged(e)}
                                             onKeyDown={(e)=>{this.onKeyDownAccount(e)}}
                                             className="input-devextreme"
                                             maxLength={20}
                                             showClearButton={false}
                                             placeholder={Config.lang("CRM_Ten_Dang_Nhap")}
                                    >
                                        <Validator >
                                            <RequiredRule message={Config.lang("CRM_Gia_tri_bat_buoc_nhap")}/>
                                        </Validator>
                                    </TextBox>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm={12}>
                                    <TextBox name={"PassW"}
                                             value={this.state.passW}
                                             onChange={(e) => this.onTextValueChanged(e)}
                                             onKeyDown={(e)=>{this.onKeyDown(e)}}
                                             className="input-devextreme"
                                             maxLength={20}
                                             showClearButton={false}
                                             mode={'password'}
                                             placeholder={Config.lang("CRM_Mat_Khau")}
                                    >
                                        <Validator >
                                            <RequiredRule message={Config.lang("CRM_Gia_tri_bat_buoc_nhap")}/>
                                        </Validator>
                                    </TextBox>
                                </Col>
                            </Row>
                        </FormGroup>
                        <Button disabled={this.state.isSubmit}
                                className="login-bt"
                                onClick={()=>{this.onEventSave()}}>
                            {!!this.state.isSubmit ?
                                (
                                    <div className={'display_row align-center valign-middle'}>
                                        <ReactLoading type={'spin'} color="#6FA3F8" width={20} height={20}/>
                                    </div>
                                )
                                : Config.lang("CRM_Dang_nhap")
                            }
                        </Button>
                        <ButtonSM id="btnSubmit"
                                  useSubmitBehavior={true}
                                  onClick={(e) => this.onFormSubmit(e)}
                                  className="hide"
                        />
                    </div>

                    <div className="error" style={{width: "85%", textAlign: "center"}}>{this.state.message}</div>
                    <Image src={require('../../images/login-bottom.png')}/>
                </div>
            </div>
        )
    }
}

export default connect(state => ({
        token: state.loading.token,
        setting: state.loading.setting,
        profile: state.loading.profile
    }),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch)
    })
)(LoginPage);
